"use client";

import React from "react";

import clsx from "clsx";

import { AnygiftPoints } from "@/components/domains/anygift/AnygiftPoints/AnygiftPoints";

import styles from "./EgiftFlow.module.scss";
import { egiftSections } from "../consts";
import { EgiftReceive } from "../EgiftReceive/EgiftReceive";
import { EgiftSend } from "../EgiftSend/EgiftSend";

import "swiper/css";
import "swiper/css/pagination";
import "../../../template/lp/Form01/styles/swipper.scss";

export function EgiftFlow(): React.ReactNode {
  return (
    <section id={egiftSections.About.id} className="bg__yellow pd__top__xl pd__bottom__xl">
      <div className="container">
        <div className="row">
          <div className="col-12 col-m-8 col-offset-m-2 pd__bottom__l">
            <p
              className={clsx("text__xxl text__bold text__center", styles.title)}
              dangerouslySetInnerHTML={{ __html: egiftSections.About.title }}
            />
            <p className="text__m text__center mg__top__m mg__bottom__m">
              eギフトとは、相手の住所を知らなくても、<span className="wsnr">メールやSNSで</span>
              受け取りURLを送ることで、
              <span className="wsnr">ギフトを贈れるサービスです。</span>
              <br />
              <br />
              お相手は会員登録不要で、配送先を入力するとギフトが届きます。
            </p>
          </div>
          <div className="col-12 col-m-8 col-offset-m-2">
            <AnygiftPoints />
          </div>
        </div>
        <EgiftSend />
        <EgiftReceive />
      </div>
    </section>
  );
}
