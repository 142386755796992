"use client";

import { useCallback, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import { useRouter } from "next/navigation";
import { Controller, useForm } from "react-hook-form";

import { LoadingOverlay } from "@/components/displays";
import { useAuth } from "@/components/domains";
import { TextField } from "@/components/inputs";
import { signupPassword } from "@/generated/axios-functions/payseAPI";
import { getErrorMessages } from "@/utils/error";

import { RegistrationPasswordFormSchema, type RegistrationPasswordFormSchemaValue } from "./schema";

export interface RegistrationPasswordFormProps {
  token: string;
}

export function RegistrationPasswordForm({
  token: queryToken,
}: RegistrationPasswordFormProps): React.ReactNode {
  const router = useRouter();
  const [serverErrorMessages, setServerErrorMessages] = useState<string[]>([]);
  const { setAccessToken } = useAuth();
  const {
    control,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<RegistrationPasswordFormSchemaValue>({
    resolver: zodResolver(RegistrationPasswordFormSchema),
    mode: "onBlur",
    defaultValues: {
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
    },
  });

  const handleOnSubmit = useCallback(
    async ({ firstName, lastName, ...restFormValues }: RegistrationPasswordFormSchemaValue) => {
      // クリアエラーメッセージ
      setServerErrorMessages([]);
      try {
        const { token: accessToken } = await signupPassword({
          ...restFormValues,
          first_name: firstName,
          last_name: lastName,
          token: queryToken,
        });
        if (accessToken) {
          setAccessToken(accessToken);
        }
        router.push("/mypage");
      } catch (error) {
        const errorMessages = getErrorMessages(error);
        setServerErrorMessages(errorMessages);
      }
    },
    [queryToken, router, setAccessToken]
  );

  return (
    <LoadingOverlay isLoading={isSubmitting}>
      <form id="registrationPassword" onSubmit={handleSubmit(handleOnSubmit)}>
        <label className="text__m" htmlFor="CustomerPassword">
          お名前
        </label>
        <div className="row">
          <div className="col-12 col-m-6">
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="lastName"
                  placeholder="姓"
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName?.message}
                />
              )}
            />
          </div>
          <div className="col-12 col-m-6">
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="firstName"
                  placeholder="名"
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName?.message}
                />
              )}
            />
          </div>
        </div>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              onBlur={() => trigger("confirmPassword")}
              id="password"
              type="password"
              label="パスワード"
              autoComplete="current-password"
              error={Boolean(errors.password)}
              helperText={errors.password?.message || "※英数記号６文字以上"}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="confirmPassword"
              type="password"
              label="確認のため上記と同じパスワードを入力してください"
              error={Boolean(errors.confirmPassword)}
              helperText={errors.confirmPassword?.message}
            />
          )}
        />
        {serverErrorMessages.length > 0 &&
          serverErrorMessages.map((message) => (
            <div key={message} className="mg__top__m text__red">
              <span>{message}</span>
            </div>
          ))}
        <div className="text__center pd__top__m">
          <button
            type="submit"
            disabled={!isValid || isSubmitting}
            className={clsx("btn inline round text__black text__m", isValid ? "yellow" : "gray")}
            form="registrationPassword"
          >
            パスワードを登録する
          </button>
        </div>
      </form>
    </LoadingOverlay>
  );
}
