import { productNameSkuMap } from "@/configs/skuDB";

export type StampTarget = {
  sku: string;
  variantId: string;
  title: string;
  /**
   * 商品名titleの後ろに
   * - true： @
   * - false: ™︎
   * を付ける
   *
   * trueの場合 nameも表示されるので注意
   */
  isRegistered: boolean;
  fullName: string;
  name: string;
  isFreeze: boolean;
  isPurchased: boolean;
  image: string;
};

/**
 * TODO: フロントエンドで使用している sku, variantId を一箇所にまとめ、
 * 名前で参照できるようにする
 */
export const stampTargets = [
  {
    sku: productNameSkuMap.basebread_koshian,
    variantId: "33022154375244",
    title: "BASE BREAD",
    isRegistered: true,
    fullName: "BASE BREAD こしあん",
    name: "こしあん",
    isFreeze: false,
    isPurchased: false,
    image: "thumb_bb_koshian_v1_1_omote",
  },
  {
    sku: productNameSkuMap.basebread_slice_plain,
    variantId: "33022154375250",
    title: "BASE BREAD",
    isRegistered: true,
    fullName: "BASE BREAD ミニ食パン・プレーン",
    name: "ミニ食パン・プレーン",
    isFreeze: false,
    isPurchased: false,
    image: "thumb_bb_slice_plain_v1_5_omote",
  },
  {
    sku: productNameSkuMap.basebread_slice_raisin,
    variantId: "33022154375251",
    title: "BASE BREAD",
    isRegistered: true,
    fullName: "BASE BREAD ミニ食パン・レーズン",
    name: "ミニ食パン・レーズン",
    isFreeze: false,
    isPurchased: false,
    image: "thumb_bb_slice_raisin_v1_3_omote",
  },
  {
    sku: productNameSkuMap.basepancake_pancakemix,
    variantId: "41330352562201",
    title: "BASE Pancake Mix",
    isRegistered: true,
    fullName: "BASE Pancake Mix パンケーキミックス",
    name: "パンケーキミックス",
    isFreeze: false,
    isPurchased: false,
    image: "thumb_bpm_v1_2_omote",
  },
  {
    sku: productNameSkuMap.basecookies_cocoa,
    variantId: "39330352562250",
    title: "BASE Cookies",
    isRegistered: true,
    fullName: "BASE Cookies ココア",
    name: "ココア",
    isFreeze: false,
    isPurchased: false,
    image: "thumb_bc_cocoa_v2_1_omote",
  },
  {
    sku: productNameSkuMap.basecookies_pepper,
    variantId: "39330352562255",
    title: "BASE Cookies",
    isRegistered: true,
    fullName: "BASE Cookies ペッパー",
    name: "ペッパー",
    isFreeze: false,
    isPurchased: false,
    image: "thumb_bc_pepper_v1_1_omote",
  },
  {
    sku: productNameSkuMap.basecookies_cheese,
    variantId: "39330352562256",
    title: "BASE Cookies",
    isRegistered: true,
    fullName: "BASE Cookies チーズ",
    name: "チーズ",
    isFreeze: false,
    isPurchased: false,
    image: "thumb_bc_cheese_v1_1_omote",
  },
  {
    sku: productNameSkuMap.basepasta_tarako,
    variantId: "40330352562206",
    title: "BASE PASTA",
    isRegistered: true,
    fullName: "BASE PASTA たらこ",
    name: "たらこ",
    isFreeze: true,
    isPurchased: false,
    image: "thumb_bp_frozen_tarako_v1_1",
  },
  {
    sku: productNameSkuMap.basepasta_creamymushroom,
    variantId: "40330352562207",
    title: "BASE PASTA",
    isRegistered: true,
    fullName: "BASE PASTA きのこクリーム",
    name: "きのこクリーム",
    isFreeze: true,
    isPurchased: false,
    image: "thumb_bp_frozen_mushroom_v1_1",
  },
  {
    sku: productNameSkuMap.basepasta_sauceyakisoba,
    variantId: "40330352562204",
    title: "BASE PASTA",
    isRegistered: true,
    fullName: "BASE PASTA ソース焼きそば",
    name: "ソース焼きそば",
    isFreeze: false,
    isPurchased: false,
    image: "thum_bp_yakisoba_v1_2_zentai",
  },
  {
    sku: productNameSkuMap.basepasta_umakaramazesoba,
    variantId: "40330352562208",
    title: "BASE PASTA",
    isRegistered: true,
    fullName: "BASE PASTA 旨辛まぜそば",
    name: "旨辛まぜそば",
    isFreeze: false,
    isPurchased: false,
    image: "thum_bp_mazesoba_v1_0_zentai",
  },
];

interface SecretItem {
  name: string;
  imageUrl: string;
}

export const secretItems: SecretItem[] = [];
