export const EgiftFaqData = {
  usageConditions: {
    title: "eギフトの利用条件",
    items: [
      {
        question: "すべてのBASE FOOD商品をeギフトで贈れますか？",
        answer:
          "一部対象外の商品があります。eギフト選択画面内に表示されている商品が対象となります。",
      },
      {
        question: "相手の本名や住所がわからなくてもギフトを贈れますか？",
        answer: "本名や住所がわからなくても贈ることができます。",
      },
      {
        question: "海外在住の方にも贈れますか？",
        answer: "贈り先は日本国内のみとなります。",
      },
    ],
  },
  paymentAndOrder: {
    title: "支払い・注文関連",
    items: [
      {
        question: "お支払い方法は何が使えますか？",
        answer: "クレジットカード決済のみご利用可能です。",
      },
      {
        question: "BASE FOOD継続コース会員ですが、ポイントやクーポンは使用できますか？",
        answer:
          "eギフトでのご注文にはポイントやクーポンはご利用いただけません。またポイント付与の対象外となります。",
      },
      {
        question: "注文完了後、商品変更やキャンセルはできますか？",
        answer: "ご注文完了後のお客さま都合による変更およびキャンセルはできません。",
      },
      {
        question: "注文完了後に名前やメッセージを変更できますか？",
        answer: "ご注文完了後は変更できません。",
      },
      {
        question: "最低購入金額はありますか？",
        answer: "商品の合計金額2,600円以上（税込）からご購入いただけます。",
      },
      {
        question: "セット内容に1袋ずつ追加できますか？",
        answer: "eギフトでは2袋単位で追加できます。",
      },
      {
        question: "BASE FOOD継続コース会員ですが、eギフトも割引対象になりますか？",
        answer: "eギフトには継続コース割引は適応されません。",
      },
    ],
  },
  giftDelivery: {
    title: "eギフトの送付・確認・有効期限",
    items: [
      {
        question: "eギフトURLはどこで確認できますか？",
        answer:
          "購入完了画面と、購入後に届くメールにてeギフトURLを確認できます。注文完了メールとは別ですのでご注意ください。\n\nこのURLを、LINEなど各SNSやメールで贈りたい相手にお伝えください。",
      },
      {
        question: "注文完了後にメールが届きません。",
        answer:
          "メールが届かない場合は「info@anygift.jp」からのメールを受信できるよう設定をお願いします。",
      },
      {
        question: "eギフトURLに住所を入力する有効期限はありますか？",
        answer:
          "ご購入日から14日後の23時59分までが有効期限です。期限までに、お相手に住所をご入力いただく必要があります。",
      },
      {
        question: "eギフトを贈った相手が住所を入力したか確認できますか？",
        answer:
          "お相手が住所入力を完了したら、ご注文者さまへメールが届きます。その後の配送に関するご案内などはお相手にのみご連絡します。",
      },
      {
        question: "eギフトを贈った相手が期限内に住所を入力しなかった場合はどうなりますか？",
        answer:
          "ご注文者さまのメールアドレスに、受け取りURL付きのメールが送られます。URLからお手続きすると、入力した住所に商品をお届けします。\n\nメールに記載されている期限内に住所の入力がなかった場合には失効します。",
      },
      {
        question: "商品を受け取れなかった場合は返金されますか？",
        answer:
          "長期不在・住所不明・受取辞退などにより商品が弊社に返送された場合、再発送や返金は承れません。",
      },
    ],
  },
};
