"use client";

import React, { useState, useEffect, useRef } from "react";

import clsx from "clsx";

import { Image } from "@/components";
import { AnygiftCtaLink } from "@/components/domains/anygift/AnygiftCtaLink";

import kvPc from "$/images/egift/kv_egift_pc.jpg";
import kvSp from "$/images/egift/kv_egift_sp.jpg";

import styles from "./HeaderSection.module.scss";

export function HeaderSection(): React.ReactNode {
  const [showLink, setShowLink] = useState(false);
  const observerRef = useRef<MutationObserver | null>(null);
  const isCartExist = useRef<boolean>(false);

  useEffect(() => {
    // カートが存在しているか確認し、状態を更新
    const checkCartExistAndUpdateLink = () => {
      const floatingCart = document.querySelector(".anygift-floating-cart");
      const cartExists = !!floatingCart;
      isCartExist.current = cartExists;

      // カートが存在しない場合のみリンクを表示
      if (!cartExists) {
        const scrollY = window.scrollY;
        const viewportHeight = window.innerHeight;
        const targetSection = document.getElementById("EgiftLineup");

        if (targetSection) {
          const targetOffsetTop = targetSection.getBoundingClientRect().top - viewportHeight;

          if (scrollY >= viewportHeight && targetOffsetTop > 0) {
            setShowLink(true);
          } else {
            setShowLink(false);
          }
        } else {
          setShowLink(false);
        }
      } else {
        setShowLink(false);
      }
    };

    // MutationObserver を使って `.anygift-floating-cart` の存在を監視
    const observerCallback = (mutations: MutationRecord[]) => {
      for (const mutation of mutations) {
        if (
          mutation.type === "childList" &&
          (mutation.addedNodes.length > 0 || mutation.removedNodes.length > 0)
        ) {
          checkCartExistAndUpdateLink();
          break;
        }
      }
    };

    observerRef.current = new MutationObserver(observerCallback);
    observerRef.current.observe(document.body, {
      childList: true,
      // subtree: trueにすろと、Safariでの動作がかなり重くなるため、falseにしている
      subtree: false,
    });

    // スクロール時の処理を追加
    const handleScroll = () => {
      checkCartExistAndUpdateLink();
    };
    window.addEventListener("scroll", handleScroll);

    // 初回実行
    checkCartExistAndUpdateLink();

    // クリーンアップ処理
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section>
      <Image
        containerClassName={clsx("clear__pc", styles.kv)}
        className={clsx(styles.headerImage)}
        alt="eギフト画像"
        src={kvSp.src}
        aspectRatio="75 / 188"
      />
      <Image
        containerClassName={clsx("clear__sp")}
        className={clsx(styles.headerImage)}
        alt="eギフト画像"
        src={kvPc.src}
        aspectRatio="351 / 148"
      />
      {showLink && <AnygiftCtaLink to="EgiftLineup" />}
    </section>
  );
}
